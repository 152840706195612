import React from "react";
import {Affix, Button} from "antd";

interface NextRoundProps {
  onClick: () => void
}

const NextRound: React.FC<NextRoundProps> = ({onClick}) => {
  return <Affix offsetTop={10} style={{marginTop: "1rem"}}>
    <Button
      shape="round"
      block
      onClick={onClick}
    >
      Next round
    </Button>
  </Affix>;
};

export default NextRound;