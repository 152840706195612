import React, {Suspense, useState} from "react";
import {useRecoilState} from "recoil";

import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";

import {teams} from "../../recoil/team";

import TeamsList from "./TeamsList";
import TeamDetailDialog from "./TeamDetailDialog";
import TeamController from "../../controller/TeamController";

import {Team} from "../../types/team";
import {useTitle} from "../../hooks";

import "./Teams.css";

const Teams: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [allTeams, setAllTeams] = useRecoilState(teams);
  useTitle("Teams");

  const submit = (team: Team) => {
    TeamController.store(team).then(() => {
      setAllTeams([team, ...allTeams]);
    });
  }

  return <>
    <TeamDetailDialog
      show={showModal}
      title={"Create new team"}
      onCancel={() => setShowModal(false)}
      onSubmit={submit}
    />
    <Button
      type="primary"
      shape="round"
      size="large"
      block
      onClick={() => setShowModal(true)}
      icon={<PlusOutlined/>}
    >
      Create new Team
    </Button>

    <div className="all-teams">
      <TeamsList teams={allTeams}/>
    </div>
  </>;
}

const QuizzesLoading: React.FC = () => {
  return <Suspense fallback={null}>
    <Teams/>
  </Suspense>
};

export default QuizzesLoading;
