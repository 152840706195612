import React from "react";

import {Button, Checkbox, Table} from "antd";
import {ColumnsType} from "antd/lib/table/interface";

import {Quiz} from "../../types/quiz";
import {TeamQuizResult} from "../../types/quizresult";

import "./QuizTable.css";
import BaseController from "../../controller/BaseController";
import ResultController from "../../controller/ResultController";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {resultsByQuiz} from "../../recoil/result";
import {jokersForQuiz} from "../../recoil/joker";
import {Joker} from "../../types/joker";
import {pointValue} from "../../recoil/quiz";
import {UUID} from "../../types/common";

interface QuizTableProps {
  quiz: Quiz;
  results: TeamQuizResult[]
  preRoundResults: Map<UUID, number>
}

const QuizTable: React.FC<QuizTableProps> = ({quiz, results, preRoundResults}) => {
  const pointDifferenceValue = useRecoilValue(pointValue);
  const setResults = useSetRecoilState(resultsByQuiz(quiz.id));
  const joker = useRecoilValue(jokersForQuiz(quiz.id));

  const onChangePoints = (result: TeamQuizResult, amount: number) => {
    const newResult: TeamQuizResult = {
      ...result,
      points: result.points + amount,
      id: result.id === "" ? BaseController.createUUID() : result.id
    };

    ResultController.storeTeamResult(newResult).then(() => {
      ResultController.getForQuiz(quiz).then(results => setResults(results));
    });
  }

  const onCheckJoker = (result: TeamQuizResult, joker: Joker) => {
    const newJokersUsed = new Set(result.jokersUsed);
    if (newJokersUsed.has(joker.id)) {
      newJokersUsed.delete(joker.id);
    } else {
      newJokersUsed.add(joker.id);
    }

    const newResult: TeamQuizResult = {
      ...result,
      jokersUsed: newJokersUsed,
      id: result.id === "" ? BaseController.createUUID() : result.id
    };

    ResultController.storeTeamResult(newResult).then(() => {
      ResultController.getForQuiz(quiz).then(results => setResults(results));
    });
  }

  const columns: ColumnsType<TeamQuizResult> = [
    {
      title: "Name",
      dataIndex: ["team", "name"],
      key: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.team.name.localeCompare(b.team.name),
    },
    {
      title: "Points",
      key: "points",
      render: (value, record) => {
        return <Button.Group>
          <Button type="dashed" onClick={() => onChangePoints(record, -1 * pointDifferenceValue)}>
            - {pointDifferenceValue}
          </Button>
          <Button type="primary">
            {record.points}
          </Button>
          <Button type="dashed" onClick={() => onChangePoints(record, pointDifferenceValue)}>
            + {pointDifferenceValue}
          </Button>
        </Button.Group>
      },
      sorter: (a, b) => a.points - b.points
    },
    {
      title: "Joker",
      key: "joker",
      render: (value, record) => {
        return joker.map(j => <Checkbox
          checked={record.jokersUsed.has(j.id)}
          onClick={() => onCheckJoker(record, j)}
        >
          {j.name}
        </Checkbox>);
      }
    }
  ];

  return <Table
    className="teams-table"
    columns={columns}
    dataSource={results}
    rowClassName={record => record.points !== preRoundResults.get(record.team.id) ? "points-changed-row": ""}
    pagination={false}
  />;
}
export default QuizTable;
