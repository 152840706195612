import React, {useEffect} from "react";
import {useRecoilValue} from "recoil";

import {title} from "../recoil/navigation";

const baseTitle = document.title;

const Header: React.FC = () => {
  const titleName = useRecoilValue(title);

  useEffect(() => {
    document.title = titleName === "" ? baseTitle : titleName + " | " + baseTitle;
  }, [titleName]);

  return <h1>{titleName || baseTitle}</h1>;
};

export default Header;