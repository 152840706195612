import React, {useState} from "react";

import {Input, Modal, Space} from "antd";

import {Joker} from "../../types/joker";
import JokerController from "../../controller/JokerController";

interface JokerDetailDialogProps {
  show?: boolean
  title: string
  onCancel: () => void
  onSubmit: (team: Joker) => void
}

const JokerDetailDialog: React.FC<JokerDetailDialogProps> = ({show, title, onCancel, onSubmit}) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const submit = () => {
    const team = JokerController.create({
      name,
      description,
    });

    onSubmit(team);
    onCancel();
  }

  const okDisabled = () => {
    return name === "" || description === "";
  }

  return <Modal visible={show} title={title} onCancel={onCancel} onOk={submit} okButtonProps={{disabled: okDisabled()}}>
    <Space direction="vertical" size={12} style={{width: "100%"}}>
      <Input placeholder="Name" value={name} onChange={event => setName(event.target.value)}/>
      <Input placeholder="Description" value={description} onChange={event => setDescription(event.target.value)}/>
    </Space>
  </Modal>;
}

export default JokerDetailDialog;
