import React from "react";
import {Empty, List} from "antd";
import {Link} from "react-router-dom";

import {Quiz} from "../../types/quiz";
import {formatDatetime} from "../../helpers";

interface QuizListProps {
  quizzes: Quiz[]
}

const QuizList: React.FC<QuizListProps> = ({quizzes}) => {
  if (quizzes.length === 0) {
    return <Empty
      description="You haven't created any quizzes, yet"
    />;
  }

  return <List
    itemLayout="horizontal"
    dataSource={quizzes}
    renderItem={quiz => (
      <List.Item>
        <List.Item.Meta
          title={<Link to={`/quiz/${quiz.id}`}>{quiz.name}</Link>}
          description={formatDatetime(quiz.time)}
        />
      </List.Item>
    )}
  />;
}

export default QuizList;
