import BaseController from "./BaseController";
import {QuizResult, TeamQuizResult} from "../types/quizresult";
import {Quiz} from "../types/quiz";
import {UUID} from "../types/common";

class ResultController extends BaseController<QuizResult> {
  create(values: Omit<QuizResult, "id">): QuizResult {
    return {
      id: BaseController.createUUID(),
      ...values
    };
  }

  getAll(): Promise<QuizResult[]> {
    throw new Error("This method is not supported for the QuizController");
  }

  async store(value: QuizResult): Promise<void> {
    const db = await this.getDB();
    await db.put("results", value, value.id);
  }

  async storeTeamResult(value: TeamQuizResult): Promise<void> {
    const result: QuizResult = {
      id: value.id,
      points: value.points,
      jokersUsed: value.jokersUsed,
      teamId: value.team.id,
      quizId: value.quiz.id
    }

    await this.store(result);
  }

  async getForQuiz(quiz: Quiz): Promise<QuizResult[]> {
    return this.getForQuizId(quiz.id);
  }

  async getForQuizId(id: UUID): Promise<QuizResult[]> {
    const db = await this.getDB();
    const byQuizIndex = db.transaction("results").objectStore("results").index("byQuiz");

    return await byQuizIndex.getAll(id);
  }
}

export default new ResultController();
