import moment from "moment";
import {DATETIME_FORMAT} from "./constants";
import {TeamQuizResult} from "./types/quizresult";
import {UUID} from "./types/common";

export function range(start: number, end: number): number[] {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function formatDatetime(date: Date): string {
  return moment(date).format(DATETIME_FORMAT);
}

export function makeTeamsPointsMap(results: TeamQuizResult[]): Map<UUID, number> {
  const result = new Map<UUID, number>();
  results.forEach(r => result.set(r.team.id, r.points));
  return result;
}
