import BaseController from "./BaseController";
import {Joker} from "../types/joker";

class JokerController extends BaseController<Joker> {
  create(values: Omit<Joker, "id">): Joker {
    return {
      id: BaseController.createUUID(),
      ...values,
    };
  }

  async getAll(): Promise<Joker[]> {
    const db = await this.getDB();
    return await db.getAll("joker");
  }

  async store(value: Joker): Promise<void> {
    const db = await this.getDB();
    await db.put("joker", value, value.id);
  }

}

export default new JokerController();
