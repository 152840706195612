import {atomFamily, selectorFamily} from "recoil";

import {QuizResult, TeamQuizResult} from "../types/quizresult";
import {UUID} from "../types/common";
import ResultController from "../controller/ResultController";
import {teams} from "./team";
import {quizById} from "./quiz";

export const resultsByQuiz = atomFamily<QuizResult[], UUID>({
  key: "resultsByQuiz",
  default: quizId => (async () => ResultController.getForQuizId(quizId))()
});

export const teamResultsByQuiz = selectorFamily<TeamQuizResult[], UUID>({
  key: "teamResultsByQuiz",
  get: quizId => async ({get}) => {
    const results = get(resultsByQuiz(quizId));
    const allTeams = get(teams);
    const quiz = get(quizById(quizId));

    if (quiz === undefined) {
      throw new Error("cannot find quit for quiz id:" + quizId);
    }

    const allResults =  results.map(teamResult => {
      const team = allTeams.find(t => t.id === teamResult.teamId);

      if (team === undefined) {
        throw new Error("cannot find team for team id:" + teamResult.teamId);
      }

      const result: TeamQuizResult = {
        team,
        quiz,
        jokersUsed: teamResult.jokersUsed,
        id: teamResult.id,
        points: teamResult.points
      }

      return result;
    });

    allTeams.filter(t => {
      const existing = allResults.find(r => r.team.id === t.id);
      return existing === undefined && quiz.teams.includes(t.id);
    }).forEach(t => {
      allResults.push({
        id: "",
        team: t,
        points: 0,
        jokersUsed: new Set<UUID>(),
        quiz
      })
    })

    return allResults;
  }
});
