import {atom, selectorFamily} from "recoil";

import {Joker} from "../types/joker";
import JokerController from "../controller/JokerController";
import {UUID} from "../types/common";
import {quizById} from "./quiz";

export const joker = atom<Joker[]>({
  key: "joker",
  default: (async () => {
    return await JokerController.getAll();
  })()
});

export const jokersForQuiz = selectorFamily<Joker[], UUID>({
  key: "jokersForQuiz",
  get: quizId => ({get}) => {
    const all = get(joker);
    const quiz = get(quizById(quizId));

    if (quiz === undefined) {
      throw new Error("cannot find quiz with id:" + quizId);
    }

    const jokerIds = new Set(quiz.joker);
    return all.filter(j => jokerIds.has(j.id));
  }
});
