import React from "react";
import {Team} from "../../types/team";

import {Empty, List} from "antd";

interface TeamsListProps {
  teams: Team[]
}

const TeamsList: React.FC<TeamsListProps> = ({teams}) => {
  if (teams.length === 0) {
    return <Empty
      description="You haven't created any teams, yet"
    />;
  }

  return <List
    itemLayout="horizontal"
    dataSource={teams}
    renderItem={team => (
      <List.Item>
        <List.Item.Meta
          title={team.name}
        />
      </List.Item>
    )}
  />;
}

export default TeamsList;
