import {Base, UUID} from "../types/common";
import {IDBPDatabase, IDBPTransaction, openDB} from "idb";
import {QuizMaster} from "../types/idbschema";
import {DATABASE_VERSIONS} from "../constants";

abstract class BaseController<T extends Base> {
  abstract create(values: Omit<T, "id">): T;
  abstract getAll(): Promise<T[]>;
  abstract store(value: T): Promise<void>;

  protected getDBName(): string {
    return "quizmaster";
  }

  protected getDBVersion(): number {
    return DATABASE_VERSIONS.INITIAL_VERSION;
  }

  protected async getDB(): Promise<IDBPDatabase<QuizMaster>> {
    return await openDB<QuizMaster>(this.getDBName(), this.getDBVersion(), {
      upgrade(database: IDBPDatabase<QuizMaster>, oldVersion: number, newVersion: number | null, transaction: IDBPTransaction<QuizMaster>) {
        switch (newVersion) {
          case DATABASE_VERSIONS.INITIAL_VERSION:
            database.createObjectStore("quizzes");
            database.createObjectStore("teams");
            database.createObjectStore("joker");
            const resultObjectStore = database.createObjectStore("results");

            resultObjectStore.createIndex(
              "byQuiz",
              "quizId",
              {unique: false}
            );
            break;
        }
      }
    });
  }

  // https://stackoverflow.com/a/2117523
  static createUUID(): UUID {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-mixed-operators
      return v.toString(16);
    });
  }
}

export default BaseController;
