import React, {useState, Suspense} from "react";
import {useRecoilState} from "recoil";

import {Button} from 'antd';

import {
  PlusOutlined
} from '@ant-design/icons';

import {Quiz} from "../../types/quiz";
import QuizList from "./QuizList";
import QuizDetailDialog from "./QuizDetailDialog";

import QuizController from "../../controller/QuizController";
import {quizzes} from "../../recoil/quiz";
import {useTitle} from "../../hooks";

import "./Quizzes.css";

const Quizzes: React.FC = () => {
  const [allQuizzes, setAllQuizzes] = useRecoilState(quizzes);
  const [showModal, setShowModal] = useState(false);
  useTitle("Quizzes");

  const createNewQuiz = (quiz: Quiz) => {
    QuizController.store(quiz).then(() => {
      setAllQuizzes([quiz, ...allQuizzes]);
    })
  }

  return <>
    <QuizDetailDialog
      title="New Quiz"
      show={showModal}
      onCancel={() => setShowModal(false)}
      onSubmit={createNewQuiz}
    />
    <Button
      type="primary"
      shape="round"
      size="large"
      block
      onClick={() => setShowModal(true)}
      icon={<PlusOutlined/>}
    >
      Create new Quiz
    </Button>

    <div className="all-quizzes"><QuizList quizzes={allQuizzes}/></div>
  </>;
};

const QuizzesLoading: React.FC = () => {
  return <Suspense fallback={null}>
    <Quizzes/>
  </Suspense>
};

export default QuizzesLoading;
