import BaseController from "./BaseController";
import {Team} from "../types/team";

class TeamController extends BaseController<Team> {
  create(values: Omit<Team, "id">): Team {
    return {
      id: BaseController.createUUID(),
      ...values,
    };
  }

  async getAll(): Promise<Team[]> {
    const db = await this.getDB();
    return await db.getAll("teams");
  }

  async store(value: Team): Promise<void> {
    const db = await this.getDB();

    await db.put("teams", value, value.id);
  }

}

export default new TeamController();
