import React, {Suspense, useState} from "react";
import {useRecoilValue} from "recoil";
import {useParams} from "react-router-dom";

import {Empty} from "antd";

import {quizById} from "../../recoil/quiz";
import {Quiz as QuizType} from "../../types/quiz";
import {formatDatetime, makeTeamsPointsMap} from "../../helpers";
import QuizMetadata from "./QuizMetadata";
import QuizTable from "./QuizTable";
import {teamResultsByQuiz} from "../../recoil/result";
import {useTitle} from "../../hooks";
import NextRound from "./NextRound";

interface QuizProps {
  quiz: QuizType
}

const Quiz: React.FC<QuizProps> = ({quiz}) => {
  const results = useRecoilValue(teamResultsByQuiz(quiz.id));
  const [preRoundResults, setPreRoundResults] = useState(makeTeamsPointsMap(results));
  useTitle(`${quiz.name} (${formatDatetime(quiz.time)})`);

  return <>
    <QuizMetadata quiz={quiz}/>
    <NextRound onClick={() => setPreRoundResults(makeTeamsPointsMap(results))}/>
    <QuizTable quiz={quiz} results={results} preRoundResults={preRoundResults}/>
  </>;
}


interface URLParameter {
  id: string
}

const QuizParameterHelper: React.FC = () => {
  const {id} = useParams<URLParameter>();
  const quiz = useRecoilValue(quizById(id));

  if (quiz === undefined) {
    return <Empty description="This quiz does not seem to exist"/>;
  }

  return <Quiz quiz={quiz}/>
}

const QuizParameterHelperLoading: React.FC = () => {
  return <Suspense fallback={null}>
    <QuizParameterHelper/>
  </Suspense>
};

export default QuizParameterHelperLoading;
