import {atom, selectorFamily} from "recoil";

import {Team} from "../types/team";
import TeamController from "../controller/TeamController";
import {UUID} from "../types/common";
import {quizById} from "./quiz";

export const teams = atom<Team[]>({
  key: "teams",
  default: (async () => {
    return await TeamController.getAll();
  })()
});

export const teamsForQuiz = selectorFamily<Team[], UUID>({
  key: "teamsForQuiz",
  get: quizId => ({get}) => {
    const all = get(teams);
    const quiz = get(quizById(quizId));

    if (quiz === undefined) {
      throw new Error("could not find any quiz for quiz id: " + quizId);
    }

    const teamIds = new Set(quiz.teams);
    return all.filter(t => teamIds.has(t.id));
  }
});
