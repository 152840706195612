import React, {useState} from "react";

import {Input, Modal} from "antd";

import {Team} from "../../types/team";
import TeamController from "../../controller/TeamController";

interface TeamDetailDialogProps {
  show?: boolean
  title: string
  onCancel: () => void
  onSubmit: (team: Team) => void
}

const TeamDetailDialog: React.FC<TeamDetailDialogProps> = ({show, title, onCancel, onSubmit}) => {
  const [name, setName] = useState<string>("");

  const submit = () => {
    const team = TeamController.create({
      name
    });

    onSubmit(team);
    onCancel();
  }

  const okDisabled = () => {
    return name === "";
  }

  return <Modal visible={show} title={title} onCancel={onCancel} onOk={submit} okButtonProps={{disabled: okDisabled()}}>
    <Input placeholder="Name" value={name} onChange={event => setName(event.target.value)}/>
  </Modal>;
}

export default TeamDetailDialog;
