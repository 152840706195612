import {atom, selectorFamily} from "recoil";

import {Quiz} from "../types/quiz";
import QuizController from "../controller/QuizController";

export const quizzes = atom<Quiz[]>({
  key: "quizzes",
  default: (async () => {
    return await QuizController.getAll();
  })()
});

export const quizById = selectorFamily<Quiz | undefined, string>({
  key: "quizById",
  get: quizId => ({get}) => {
    // this dependency is not ideal, since every selector will be recalculated every time any quiz is modified
    const all = get(quizzes);
    return all.find(quiz => quiz.id === quizId);
  }
});

export const pointValue = atom<number>({
  key: "pointValue",
  default: 10
});
