import React from "react";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";

import {Button, Descriptions, InputNumber} from "antd";

import {Quiz} from "../../types/quiz";
import {jokersForQuiz} from "../../recoil/joker";
import {teamsForQuiz} from "../../recoil/team";
import {pointValue} from "../../recoil/quiz";

interface PointButtonProps {
  points: number
}

const PointButton: React.FC<PointButtonProps> = ({points}) => {
  const [p, setP] = useRecoilState(pointValue);

  const type = p === points ? "primary": "default";

  return <Button type={type} onClick={() => setP(points)}>
    {points}
  </Button>
}

interface QuizMetadataProps {
  quiz: Quiz;
}

const QuizMetadata: React.FC<QuizMetadataProps> = ({quiz}) => {
  const jokers = useRecoilValue(jokersForQuiz(quiz.id));
  const teams  = useRecoilValue(teamsForQuiz(quiz.id));
  const setPointValue = useSetRecoilState(pointValue);
  const resetPointValue = useResetRecoilState(pointValue);

  return <Descriptions bordered>
    <Descriptions.Item label="Joker">{jokers.map(j => j.name).join(", ")}</Descriptions.Item>
    <Descriptions.Item label="Teams">{teams.map(t => t.name).join(", ")}</Descriptions.Item>
    <Descriptions.Item label="Points">
      <Button.Group>
        <PointButton points={5}/>
        <PointButton points={10}/>
        <InputNumber
          placeholder="Custom"
          onChange={value => typeof value === "number" ? setPointValue(value) : resetPointValue()}
        />
      </Button.Group>
    </Descriptions.Item>
  </Descriptions>;
}

export default QuizMetadata;
