import BaseController from "./BaseController";
import {Quiz} from "../types/quiz";

class QuizController extends BaseController<Quiz>{
  create(values: Omit<Quiz, "id">): Quiz {
    return {
      id: BaseController.createUUID(),
      ...values,
    };
  }

  async store(quiz: Quiz) {
    const db = await this.getDB();

    await db.put("quizzes", quiz, quiz.id);
  }

  async getAll(): Promise<Quiz[]> {
    const db = await this.getDB();
    const all = await db.getAll("quizzes");
    return all.sort(((a, b) => {
      if (a === b) {
        return 0;
      }
      return a < b ? 1 : -1;
    }))
  }
}

export default new QuizController();
