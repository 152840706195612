import {useEffect} from "react";
import {useSetRecoilState} from "recoil";
import {title as recoilTitle} from "./recoil/navigation";

export function useTitle(title: string): void {
  const setTitle = useSetRecoilState(recoilTitle);

  useEffect(() => {
    setTitle(title);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
