import React, {Suspense, useState} from "react";
import {useRecoilState} from "recoil";

import {PlusOutlined} from "@ant-design/icons";
import {Button} from "antd";

import JokerDetailDialog from "./JokerDetailDialog";
import JokerList from "./JokerList";

import {Joker as JokerType} from "../../types/joker";
import {joker} from "../../recoil/joker";
import JokerController from "../../controller/JokerController";
import {useTitle} from "../../hooks";

import "./Joker.css";

const Joker: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [allJoker, setAllJoker] = useRecoilState(joker);

  useTitle("Joker")

  const submit = (j: JokerType)  => {
    JokerController.store(j).then(() => {
      setAllJoker([j, ...allJoker]);
    })
  }

  return <>
    <JokerDetailDialog
      title="Create new joker"
      show={showModal}
      onCancel={() => setShowModal(false)}
      onSubmit={submit}
    />
    <Button
      type="primary"
      shape="round"
      size="large"
      block
      onClick={() => setShowModal(true)}
      icon={<PlusOutlined/>}
    >
      Create new Joker
    </Button>

    <div className="all-joker">
      <JokerList joker={allJoker}/>
    </div>
  </>;
}

const JokerLoading: React.FC = () => {
  return <Suspense fallback={null}>
    <Joker/>
  </Suspense>
};

export default JokerLoading;
