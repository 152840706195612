import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {HashRouter as Router, Link, Switch, Route} from 'react-router-dom';

import {
  TeamOutlined,
  QuestionCircleOutlined,
  HomeOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';

import Quizzes from "./quizzes/Quizzes";
import Teams from "./teams/Teams";
import AppHeader from "./Header";
import Home from "./home/Home";
import Joker from "./joker/Joker";
import Quiz from "./quiz/Quiz";

import "./App.css";

const {Header, Content, Footer, Sider} = Layout;

const currentYear = new Date().getFullYear();

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <Router>
    <Layout style={{minHeight: '100vh'}}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="logo">
          <Link to="/">
            <img src="/logo/64.png" alt="" className="logo-image"/>
          </Link>
        </div>
        <Menu theme="dark" selectedKeys={[]} mode="inline">
          <Menu.Item key="home" icon={<HomeOutlined/>}>
            Home
            <Link to="/"/>
          </Menu.Item>
          <Menu.Item key="quizzes" icon={<QuestionCircleOutlined/>}>
            Quizzes
            <Link to="/quizzes"/>
          </Menu.Item>
          <Menu.Item key="teams" icon={<TeamOutlined/>}>
            Teams
            <Link to="/teams"/>
          </Menu.Item>
          <Menu.Item key="joker" icon={<RadarChartOutlined/>}>
            Joker
            <Link to="/joker"/>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background site-layout-header"><AppHeader/></Header>
        <Content style={{ margin: '16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Switch>
              <Route path="/" exact component={Home}/>
              <Route path="/quizzes" component={Quizzes}/>
              <Route path="/teams" component={Teams}/>
              <Route path="/joker" component={Joker}/>
              <Route path="/quiz/:id" component={Quiz}/>
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Quizmaster &copy; 2020 - {currentYear}
          &nbsp;<a href="https://jannisfink.de" rel="nofollow noreferrer noopener" target="_blank">Jannis Fink</a>
        </Footer>
      </Layout>
    </Layout>
    </Router>
  );
}

export default App;
