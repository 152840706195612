import React from "react";
import {Empty, List} from "antd";

import {Joker} from "../../types/joker";

interface JokerListProps {
  joker: Joker[]
}

const JokerList: React.FC<JokerListProps> = ({joker}) => {
  if (joker.length === 0) {
    return <Empty
      description="You haven't created any jokers, yet"
    />;
  }

  return <List
    itemLayout="horizontal"
    dataSource={joker}
    renderItem={j => (
      <List.Item>
        <List.Item.Meta
          title={j.name}
          description={j.description}
        />
      </List.Item>
    )}
  />;
}

export default JokerList;
