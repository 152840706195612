import React from "react";

import {Link} from "react-router-dom";

import {Card, Col, Row} from "antd";

import "./Home.css";
import {useTitle} from "../../hooks";

interface HomeCartProps {
  imgSrc: string
  moreLink?: string
  title: string
  description: string
}

const HomeCart: React.FC<HomeCartProps> = ({imgSrc, moreLink, title, description}) => {
  const extra = moreLink ? <Link to={moreLink}>More</Link> : undefined;

  return <Card
    hoverable
    bordered={false}
    style={{maxWidth: "30rem"}}
    cover={<img alt="cover" src={imgSrc}/>}
    extra={extra}
  >
    <Card.Meta title={title} description={description}/>
  </Card>
}

const Home: React.FC = () => {
  useTitle("");

  return <>
    <p style={{textAlign: "center"}}>
      With quizmaster, you can keep track of your attendees points and the jokers they have used. Simply follow the
      steps described below to create your teams, jokers and quizzes and start playing!
    </p>
    <div className="home-feature-row">
      <Row gutter={16}>
        <Col>
          <HomeCart
            imgSrc="/screenshots/teams.png"
            moreLink="/teams"
            title="1. Create Teams"
            description="Create a new teams for each one that wants to attend the quiz. When created, you can add
          each team to as many quizzes as you like."
          />
        </Col>
        <Col>
          <HomeCart
            imgSrc="/screenshots/joker.png"
            moreLink="/joker"
            title="2. Create Joker"
            description="Create any joker you want to use in your quiz. You can add as many joker as you like, but you
          don't need to use every joker in every quiz. When creating the quiz, you can choose the jokers you want to use."
          />
        </Col>
      </Row>
    </div>
    <div className="home-feature-row">
      <Row style={{marginTop: "2rem"}} gutter={16}>
        <Col>
          <HomeCart
            imgSrc="/screenshots/quiz.png"
            moreLink="/quizzes"
            title="3. Create Quizzes"
            description="When you have created your teams and joker, it is time to create a new quiz. Select the teams
            and joker you want to use for that quiz, gut it a name and you are good to go!"
          />
        </Col>
        <Col>
          <HomeCart
            imgSrc="/screenshots/quizdetail.png"
            title="4. Play"
            description="Simply click on the quiz that you want to play next. You will be presented with a page where you
          can keep track of all the points scored and points collected for every single team."
          />
        </Col>
      </Row>
    </div>
  </>;
}

export default Home;