import React, {Suspense, useState} from "react";
import {DatePicker, Input, Modal, Space, Select} from "antd";
import {Moment} from "moment";
import {useRecoilValue} from "recoil";

import {Quiz} from "../../types/quiz";
import QuizController from "../../controller/QuizController";

import {DATETIME_FORMAT} from "../../constants";
import {range} from "../../helpers";
import {teams} from "../../recoil/team";
import {joker} from "../../recoil/joker";
import {UUID} from "../../types/common";

interface QuizDetailDialogProps {
  show?: boolean
  title: string
  onCancel: () => void
  onSubmit: (quiz: Quiz) => void
}

const ALL_BUT_TEN_MULTIPLES = range(0, 60).filter(value => value % 10 !== 0);

const QuizDetailDialog: React.FC<QuizDetailDialogProps> = ({show, title, onCancel, onSubmit}) => {
  const [name, setName] = useState<string>("");
  const [time, setTime] = useState<Moment | undefined>(undefined);
  const [quizTeams, setQuizTeams] = useState<UUID[]>([]);
  const [quizJokers, setQuizJokers] = useState<UUID[]>([]);
  const allTeams = useRecoilValue(teams);
  const allJokers = useRecoilValue(joker);

  const submit = () => {
    if (time === undefined) {
      return;
    }

    const quiz = QuizController.create({
      name,
      time: time.toDate(),
      teams: quizTeams,
      joker: quizJokers,
    });

    onSubmit(quiz);
    onCancel();
  }

  const okDisabled = () => {
    return time === undefined || name === "";
  }

  return <Modal title={title} visible={show} onCancel={onCancel} onOk={submit} okButtonProps={{disabled: okDisabled()}}>
    <Space direction="vertical" size={12} style={{width: "100%"}}>
      <Input placeholder="Name" value={name} onChange={event => setName(event.target.value)}/>
      <DatePicker
        format={DATETIME_FORMAT}
        style={{width: "100%"}}
        showTime={{
          hideDisabledOptions: true,
        }}
        disabledMinutes={() => ALL_BUT_TEN_MULTIPLES}
        value={time}
        onChange={event => setTime(event === null ? undefined : event)}
      />
      <Select
        mode="multiple"
        style={{width: "100%"}}
        allowClear
        placeholder="Select teams (can also be added/deleted later)"
        onChange={ids => setQuizTeams(ids)}
        value={quizTeams}
      >
        {allTeams.map(team => (
          <Select.Option value={team.id} key={team.id}>{team.name}</Select.Option>
        ))}
      </Select>
      <Select
        mode="multiple"
        style={{width: "100%"}}
        allowClear
        placeholder="Select jokers (can also be added/deleted later)"
        onChange={ids => setQuizJokers(ids)}
        value={quizJokers}
      >
        {allJokers.map(j => (
          <Select.Option value={j.id} key={j.id}>{j.name}</Select.Option>
        ))}
      </Select>
    </Space>
  </Modal>;
}

const QuizDetailDialogLoading: React.FC<QuizDetailDialogProps> = (props) => {
  return <Suspense fallback={null}>
    <QuizDetailDialog {...props}/>
  </Suspense>
}

export default QuizDetailDialogLoading;
